import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "components/image";
import Link from "components/link";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";
import style from "styles/pages/products.module.css";
import wardenSm from "images/products-warden-sm.svg";
import wardenLg from "images/products-warden-lg.svg";
import scannerSm from "images/products-scanner-sm.svg";
import scannerLg from "images/products-scanner-lg.svg";
import trainerSm from "images/products-trainer-sm.svg";
import trainerLg from "images/products-trainer-lg.svg";
import storyfierSm from "images/products-storyfier-sm.svg";
import storyfierLg from "images/products-storyfier-lg.svg";

function ProductsIndexPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let productSections = pageContent.products.map((product, index) => {
    if (index % 2 === 1) product.reverse = true;
    return <ProductSection
      key={product.headerText} {...product}/>;
  });
  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-8 z-10 relative py-40">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap">
          {pageContent.headerText}
        </h3>
        <div className="text-center">
          <Link to={pageContent.ctaUrl} className="btn btn-orange mt-6 inline-block">
            {pageContent.ctaText}
          </Link>
        </div>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS} objectFit="contain" alt="Background"
        imgStyle={{paddingTop: "144px"}}
        fluid={data.backgroundImage.childImageSharp.fluid}/>
    </section>
    {productSections}
    <div className="pb-48"/>
  </DefaultLayout>;
}

function ProductSection(props) {
  let images;
  let reverseClassName = "";
  if (props.reverse === true) {
    reverseClassName = "md:flex-row-reverse";
  }
  if (/warden/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={wardenSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={wardenLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "350px", top: "48px", right: "20px"}}/>
    </React.Fragment>;
  }
  if (/scanner/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={scannerSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={scannerLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "350px", top: "48px", right: "20px"}}/>
    </React.Fragment>;
  }
  if (/trainer/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={trainerSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={trainerLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "480px", top: "48px", right: "20px"}}/>
    </React.Fragment>;
  }
  if (/storyfier/i.test(props.headerText)) {
    images = <React.Fragment>
      <img className="block md:hidden pointer-events-none" src={storyfierSm}
        alt={props.headerText}/>
      <img className="absolute hidden md:block pointer-events-none" src={storyfierLg}
        alt={props.headerText}
        style={{maxWidth: "initial", height: "400px", top: "-100px", right: "20px"}}/>
    </React.Fragment>;
  }
  return <section className="min-h-screen flex items-center">
    <div className="container mx-auto px-8 py-24 z-10 relative max-w-4xl">
      <div className={`flex ${reverseClassName} flex-col md:flex-row items-center`}>
        <div className={`${style.productImageContainer} mb-4 md:mb-0 relative flex-none`}>
          {images}
        </div>
        <div>
          <h3 className="font-bold text-3xl md:text-5xl leading-tight">
            {props.headerText}
          </h3>
          <p className="mt-2">{props.descriptionText}</p>
          <Link className="btn btn-orange-outline inline-block mt-6" to={props.ctaUrl}>
            {props.ctaText}
          </Link>
        </div>
      </div>
    </div>
  </section>;
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/products.json"}) {
      childPagesJson {
        title
        description
        headerText
        ctaText
        ctaUrl
        products {
          headerText
          descriptionText
          ctaText
          ctaUrl
        }
      }
    }
    backgroundImage: file(relativePath: {eq: "background-products-1.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(ProductsIndexPage);
